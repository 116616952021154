

/* Blog Details Container */
.blog-details {
  max-width: var(--max-width);
  margin: 2rem auto;
  padding: 0 1.5rem;
  background: var(--background-color);
  border-radius: 8px;
  box-shadow: var(--shadow);
  --max-width: 800px;
}

/* Header Styles */
header {
  padding: 2rem 0;
  border-bottom: 1px solid var(--border-color);
}

.blog-details-title{
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text-color);
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.blog-details-date {
  font-size: 1rem;
  color: var(--secondary-color);

}

/* Blog Image Styles (uncomment if using separate image) */
/* .blog-image {
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: cover;
  border-radius: 4px;
  margin: 1.5rem 0;
  box-shadow: var(--shadow);
} */
.blog-content p img {
  max-width: 50%; /* Limits each image to half the container width for two images */
  height: auto;
  margin: 0.5rem 0.5rem 0.5rem 0; /* Spacing between images */
  border-radius: 4px;
  display: inline-block; /* Allows two images to sit side-by-side if space permits */
  vertical-align: middle;
  object-fit: cover; /* Ensures image aspect ratio is maintained */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}
/* Blog Content Styles */
.blog-content {
  padding: 2rem 0;
  line-height: 1.6;
  color: var(--text-color);
}

.blog-content p {
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
}

.blog-content h2,
.blog-content h3 {
  margin: 1.5rem 0 1rem;
  color: var(--primary-color);
}

.blog-content h2 {
  font-size: 1.75rem;
  font-weight: 600;
}

.blog-content h3 {
  font-size: 1.25rem;
  font-weight: 500;
}

.blog-content a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.2s ease;
}

.blog-content a:hover {
  color: #1557b0;
  text-decoration: underline;
}

.blog-content img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin: 1rem 0;
  box-shadow: var(--shadow);
}

/* Loading, Error, and No Blog States */
.loading,
.error,
.no-blog {
  text-align: center;
  padding: 2rem;
  font-size: 1.1rem;
  color: var(--secondary-color);
}

.error {
  color: #d32f2f;
}

.no-blog {
  color: #757575;
}

/* Responsive Design */
@media (max-width: 768px) {
  .blog-details {
    margin: 1rem;
    padding: 0 1rem;
  }

  .blog-title {
    font-size: 2rem;
  }

  .blog-content {
    padding: 1.5rem 0;
  }

  .blog-content h2 {
    font-size: 1.5rem;
  }

  .blog-content h3 {
    font-size: 1.15rem;
  }
}

@media (max-width: 480px) {
  .blog-title {
    font-size: 1.75rem;
  }

  .blog-content p {
    font-size: 1.6rem;
  }
}

/* Accessibility Enhancements */
.blog-details:focus {
  outline: none;
}

.blog-title:focus,
.blog-content a:focus {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Smooth transitions for dynamic content */
.blog-details,
.blog-content {
  transition: opacity 0.3s ease-in-out;
}

.blog-details[aria-hidden="true"] {
  opacity: 0;
}