.update-password-section {
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.section-title {
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  color: #333;
}

.update-password-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.form-control {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn {
  padding: 0.75rem;
  font-size: 1.5rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 30px;
}

.btn:hover {
  background-color: #0056b3;
}

@media (max-width: 576px) {
  .update-password-section {
    padding: 1rem;
  }

  .section-title {
    font-size: 1.25rem;
  }

  .form-control {
    font-size: 0.875rem;
  }

  .btn {
    font-size: 0.875rem;
  }
}
