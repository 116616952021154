.bestabib__home-doctor-list-details {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

.bestabib__home-doctor-list {
  flex: 1 1 100%;
  margin-bottom: 1rem;
}

.bestabib__home-doctor-details {
  flex: 1 1 100%;
}

.bestabib__home-card-doctor {
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.bestabib__home-card-doctor:hover,
.bestabib__home-card-doctor.showSelectedDoctor {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.bestabib__home-doctor-list h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.bestabib__home-blog-list {
  margin-top: 2rem;
}

.bestabib__home-blog-list ul {
  list-style-type: none;
  padding: 0;
}

.bestabib__home-blog-list li {
  margin-bottom: 1rem;
}

/* Responsive styles */
@media (min-width: 768px) {
  .bestabib__home-doctor-list-details {
    gap: 1.5rem;
  }

  .bestabib__home-doctor-list,
  .bestabib__home-doctor-details {
    flex: 1 1 calc(50% - 1rem); /* Adjust width for 2 columns */
  }
}

@media (min-width: 992px) {
  .bestabib__home-doctor-list {
    flex: 2 2; /* Larger width for doctor list */
  }

  .bestabib__home-doctor-details {
    flex: 2; /* Smaller width for doctor details */
  }
}

@media (min-width: 1200px) {
  .bestabib__home-doctor-list-details {
    gap: 2rem;
  }
}

/* Add any additional styling below */

.home-container {
  min-height: 19em;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
  max-width: 1200px;
  margin: auto;
}

.search-box {
  margin-bottom: 1rem;
  margin-top: 7rem;
}

.doctor-list {
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.doctor-card {
  cursor: pointer;
  padding: 1rem;
  border-radius: 8px;
  transition: background-color 0.3s;
  width: 100%;
}

.doctor-card.selected {
  background-color: #7fc1e8;
}

.blog-list h3 {
  color: #3c3c3d;
  cursor: pointer;
  margin: 0.5rem 0;
}

@media (max-width: 768px) {
  .home-container {
    padding: 0.5rem;
  }

  .doctor-list,
  .blog-list {
    padding: 0.5rem;
  }
}
