.profile-container {
  background-color: #fff;
}
.bestabib__form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
  margin: auto;
  background: #fff;
}

.bestabib__form-group {
  margin-bottom: 20px;
  width: 100%;
}

.bestabib__label {
  font-weight: bold;
  margin-bottom: 8px;
}

.bestabib__input {
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.bestabib__button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.bestabib__loading {
  font-size: 20px;
  text-align: center;
}

.bestabib__error {
  color: red;
  font-size: 20px;
  text-align: center;
}

/* ProfileScreen.css */
.profile-container {
  display: flex;
}

.sidebar {
  flex: 1;
  border-right: 1px solid #ccc;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.sidebar button {
  background: none;
  border: none;
  text-align: left;
  margin-bottom: 16px;
  cursor: pointer;
  padding: 8px;
}

.sidebar button.active {
  background-color: #ddd; /* or any color of your choice */
  border-radius: 4px;
}

.sidebar-button:hover::after {
  right: 10px;
}
.content {
  flex: 3;
  padding: 1em;
}

.selected {
  color: rgb(28, 28, 28); /* or any color you like */
}

/* ProfileScreen.css */

.profile-container {
  display: flex;
}

.sidebar {
  flex: 1;
}

.content {
  flex: 3;
}

.sidebar-button {
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  position: relative;
  color: rgb(24, 24, 24);
}

.sidebar-button.active {
  background-color: #f0f0f0;
}

.sidebar-button::after {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: width 0.3s;
}

.sidebar-button.active::after {
  content: '›';
  color: rgb(11, 11, 70);
  font-weight: bold;
}

.bestabib__form-group {
  margin-bottom: 20px;
}

/* For wrapping form group */
.bestabib__form-wrapper {
  display: flex;
  justify-content: space-between; /* Adjust space between the two columns */
}

/* Address specific styling */
.bestabib__form-address {
  flex: 1;
  margin-left: 20px; /* Adjust the margin as per your design */
}

.bestabib__profile-account-info {
  display: flex;
}

/* General Styling */

.bestabib__profile-account {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.bestabib__profile-account-title {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 1.5em;
}

.bestabib__form-group {
  margin-bottom: 20px;
}

.bestabib__label {
  display: block;
  font-weight: 500;
  margin-bottom: 8px;
}

.bestabib__input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #f4f4f4;
  transition: border 0.3s ease;
}

.bestabib__input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.bestabib__button {
  background-color: #007bff;
  color: #ffffff;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.bestabib__button:hover {
  background-color: #0056b3;
}

/* Account Settings Specific Styling */
.bestabib__profile-account-info {
  display: flex;
  gap: 20px;
}

.bestabib__profile-account-info-details,
.bestabib__profile-account-info-address {
  width: 50%;
}

/* For mobile responsiveness */
@media (max-width: 768px) {
  .bestabib__profile-account-info {
    flex-direction: column;
  }

  .bestabib__profile-account-info-details,
  .bestabib__profile-account-info-address {
    width: 100%;
  }
}
