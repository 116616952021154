.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.pageButton {
  background-color: #fff; /* Default to white for non-selected pages */
  color: black; /* Default text color */
  border: 1px solid black; /* Add a border to make it more distinguishable */
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pageButton:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Slight gray on hover */
}

.active {
  background-color: black; /* Black for the selected page */
  color: white; /* White text color for the selected page */
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.pageButton {
  background-color: #fff; /* Default to white for non-selected pages */
  color: black; /* Default text color */
  border: 1px solid black; /* Add a border to make it more distinguishable */
  padding: 0rem 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pageButton:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Slight gray on hover */
}

.active {
  background-color: black; /* Black for the selected page */
  color: white; /* White text color for the selected page */
}
