.doctor-card {
  display: flex;
  gap: 2em;
}

.doctor-card-image {
  border-radius: 50%;
}
.doctor-card-title {
  font-size: 1.989rem;
  line-height: 1.0834933333;
  letter-spacing: -0.003em;
  font-family: var(--font-family-title);
  margin: 0;
}

.doctor-card-speciality,
.doctor-card-address {
  font-size: 1.8rem;
}

.doctor-card-speciality,
.doctor-card-address {
  font-size: 1.4rem;
  margin: 0;
}
/* Rating Section */

.bestabib__rating-sectionlity {
  display: flex;
  flex-direction: column;
}

.bestabib__rating-section .stars {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
}
.write-review-button {
  color: #06c;
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 1.381002381;
  font-weight: 400;
  letter-spacing: 0.011em;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}
.write-review-button:hover {
  text-decoration: underline;
}

/* Hidden by default */
.bestabib__rating-section-review-form {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s, opacity 0.5s 0.5s, visibility 0s 1s;
}

/* Visible state */
.bestabib__rating-section-review-form.visible {
  margin-top: 2em;
  max-height: 500px;
  opacity: 1;
  visibility: visible;
  transition: max-height 1s ease, opacity 0.5s ease;
  /* transition: all 1s ease; */
}

/* .pagination .active .page-link {
  background-color: black !important;
  color: red !important;
} */
