/* DoctorList.css */

.doctor-list-container {
  display: flex;
  gap: 20px; /* Space between list and details */
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
}

.doctor-list {
  flex: 2;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.doctor-details {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Doctor card styling */
.doctor-card {
  padding: 15px;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.doctor-card.selected {
  background-color: #e0f7fa; /* Highlight selected doctor */
}

/* Responsive styling */
@media (max-width: 920px) {
  .doctor-list-container {
    flex-direction: column; /* Stack items vertically */
  }

  .doctor-list,
  .doctor-details {
    flex: none; /* Reset flex size */
    width: 100%; /* Full width */
    margin-bottom: 20px; /* Add spacing between sections */
  }
}
