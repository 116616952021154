/* .bestabib__doctor-details {
  position: -webkit-sticky;
  position: sticky;
  top: 3em;
} */

.bestabib__doctor-details {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Inter', sans-serif;
}
.bestabib__doctor-details-card-content {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.bestabib__doctor-btn-right {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.bestabib__doctor-details {
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 1.8em;
  padding: 2.4em;
  margin-bottom: 1em;
  margin-top: 1.5em;
}

.bestabib__doctor-details-card-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #007bff;
}

.bestabib__doctor-details-card-action {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding-left: 10px;
}
.bestabib__doctor-details-btn-addToMydoctors {
  background-color: #000;
  color: #fff;
  border-radius: 20px;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font: size 1.6rem;
  font-family: Arial, sans-serif;
  margin-bottom: 1em;
}

.bestabib__doctor-details-btn-addToMydoctors[disabled] {
  background-color: #d3d3d3; 
  color: #a9a9a9; 
  cursor: not-allowed;
  box-shadow: none;
  border: 1px solid #a9a9a9;
}

.bestabib__doctor-details-btn-addToMydoctors[disabled]:hover {
  background-color: #d3d3d3; 
  color: #a9a9a9;
  box-shadow: none;
  border: 1px solid #a9a9a9;
}


.bestabib__doctor-details-info {
  flex: 3 1;
}

.bestabib__doctor-details-card-info-title {
  font-size: 1.989rem;
  line-height: 1.0834933333;
  letter-spacing: -0.003em;
  font-family: var(--font-family-title);
  margin: 0;
  text-decoration: none;
}

.bestabib__doctor-details-card-info-speciality {
  font-size: 1.6rem;
  color: #007bff;
  font-weight: 500;
  margin: 4px 0;
}

.bestabib__doctor-details-card-info-address {
  font-size: 14px;
  color: #666;
}

.bestabib__doctor-details-card-action {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.doctor-card-phone {
  font-size: 1.6rem;
  color: #1a202c;
  font-weight: 600;
}

.bestabib__doctor-details-card-action {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.bestabib__doctor-card-details_contact:active {
  transform: scale(
    0.98
  );
}


.doctor-card-email {
  margin: 5px 0; 
  font-weight: 400; 
  color: #333; 
}

.doctor-card-email {
  font-size: 1.6rem;
}

.bestabib__doctor-card-details_contact {
  display: flex;
  gap: 0.5em;
  background-color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  position: relative;
  border: 1px solid #dadce0;
  border-radius: 34px;
  font-size: 1.4rem;
  width: 6em;
  color:#1c1c1c;

}

.bestabib__doctor-card-details_contact svg {
  color: #06c; 
  width: 20px;
  height: 20px;
}

.bestabib__doctor-card-details_contact:hover svg {
  color: #ebeced;
  transform: translate(1px);
}
.bestabib__doctor-card-details_contact:hover{
  color: #f3f1f1;
  border-color: #1763ae;
}
/* Reviews */
.bestabib__doctor-details-reviews {
  margin-top: 20px;
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.review-item {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.review-date {
  font-size: 0.85em;
  color: #888;
  margin-bottom: 5px;
}

.review-text {
  font-size: 1em;
  color: #333;
  line-height: 1.5;
}

/* Toggle Button */
.reviews-toggle-button {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  color: black;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: all 0.3s;
}

.reviews-toggle-button:hover {
  background-color: #f2f2f2;
}

.reviews-toggle-button svg {
  width: 12px;
  height: auto;
}



/* Responsive Design */
@media (max-width: 768px) {

  .bestabib__doctor-details {
    max-width: 40rem;
  }
  .bestabib__doctor-details-card-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .bestabib__doctor-details-card-photo {
    width: 80px;
    height: 80px;
  }

  .bestabib__doctor-details-card-info-title {
    font-size: 18px;
  }

  .bestabib__doctor-card-details_contact {
    width: 100%;
  }

  .bestabib__doctor-details-btn-addToMydoctors {
    display: block;
    margin: 0 auto; 
    text-align: center;
  }
}