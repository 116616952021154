/* BlogList.css */
.blogs-container {
  background: #f0f0f5; /* Light background color for a clean, minimalistic look */
  padding: 2rem;
}

.blog-list {
  margin-bottom: 2rem;
}

.blog-list-title {
  font-size: 2rem;
  color: #333;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}

.blog-list-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px; /* Keep the grid constrained for balance, but .blogs-container is full-width */
  margin: 0 auto; /* Center the grid */
}

.blog-list-item {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
  overflow: hidden;
}

.blog-content-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.5rem;
}

.blog-image {
  flex-shrink: 0;
  width: 150px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}

.blog-text-content {
  flex: 1;
  padding-left: 1rem;
  border-left: 1px solid #e0e0e0; /* Vertical divider */
}

.blog-title {
  font-size: 1.7rem;
  margin: 0 0 0.5rem 0;
  color: #333;
  font-weight: 600;
}

.blog-summary {
  font-size: 1.4rem;
  margin: 0;
  color: #666;
  line-height: 1.4;
}

.blog-list-item:hover {
  transform: translateY(-5px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .blog-content-wrapper {
    flex-direction: column;
    align-items: stretch;
    padding: 1rem;
  }

  .blog-image {
    width: 100%;
    height: 200px;
    margin-bottom: 1rem;
  }

  .blog-text-content {
    padding-left: 0;
    border-left: none;
  }
  .blog-summary {
    font-size: 1.4rem;
  }
  .blog-title {
    font-size: 1.1rem;
  }

  .blog-summary {
    font-size: 1.4rem;
  }
}

.load-more-container {
  text-align: center;
  margin-top: 2rem;
}

.load-more-btn {
  background-color: #007bff; 
  color: #fff; 
  border: none; 
  padding: 12px 24px; 
  font-size: 1.6rem; 
  font-weight: 600; 
  border-radius: 30px; 
  cursor: pointer; 
  transition: all 0.3s ease-in-out; 
}

.load-more-btn:hover {
  background: #0056b3;
}