.TermsOfService {
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .TermsOfService h1,
  .TermsOfService h2 {
    color: #333;
  }
  
  .TermsOfService p {
    font-size: 1.4rem;
    line-height: 1.6;
    color: #555;
  }
  
  .TermsOfService ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .TermsOfService ul li {
    margin-bottom: 10px;
  }
  
  .TermsOfService a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .TermsOfService a:hover {
    text-decoration: underline;
  }
  