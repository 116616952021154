/* index.css */

html {
  font-size: 62.5%;
}
body {
  font-size: 1.7rem; 
  font-family: 'Source Serif Pro', Georgia, Cambria, "Times New Roman", Times, serif;
  color: var(--text-color);
  background-color: var(--background-page-overview-color);
  line-height: 1.47;
  margin: 0;
}

/* CSS Variables for colors and fonts */
:root {
  --background-page-overview-color: #f5f5f7;
  --button-background-color: #0071e3;
  --button-hover-background-color: #0056b3;
  --gradient-text: linear-gradient(90deg, #e8e8ff 0%, #a9d4ff 100%);

  --background-blogs-color: #f0f0f5;
  --color-footer: #f5f5f7;
  --footer-text-color: #fff;
  --footer-link-color: #fff;

  --primary-color: #007bff;
  --secondary-color: #28a745;
  --background-color: #f9f9f9;
  --color-toggleMenu: #28328c;
  --text-color: #333;
  --accent-color: #e6f7ff;
}

/* Base styling for anchor elements */
a {
  color: inherit;
  text-decoration: none;
}
