.bestabib__header {
  background-color: #fff;
}
.bestabib__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  height: 57px;
  max-height: 54px;
}

.bestabib__navbar-links {
  display: flex;

  /* flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center; */
}

/* .bestabib__navbar-links_logo {
  margin-right: 2rem;
} */

.bestabib__navbar-links_logo img {
  width: 108.56px;
  height: 33.02px;
}
.bestabib__navbar-links_container Menu {
  gap: 4.1em;
  margin: 0;
}

.bestabib__navbar-links_container p a,
.bestabib__navbar-sign p a,
.bestabib__navbar-sign button a,
.custom-select {
  text-decoration: none;
  color: #1d1d1f;
  font-size: 1.6rem;
  letter-spacing: -0.01em;
}
.bestabib__navbar-links_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bestabib__navbar-links_container p a,
.bestabib__navbar-sign p a {
  position: relative;
  font-weight: 400;
  letter-spacing: -0.01em;
}

.bestabib__navbar-links_container p a.active::before {
  position: absolute;
  content: "";
  width: 35%;
  height: 1px;
  border-bottom: 2px solid black;
  bottom: -6px;
}

.bestabib__navbar-links_container p a:before,
.bestabib__navbar-sign p a:before {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  border-bottom: 2px solid rgb(152, 152, 152);
  bottom: -6px;
  transition: width 0.3s;
}

.bestabib__navbar-links_container p a:hover:before,
.bestabib__navbar-sign p a:hover::before {
  width: 35%;
}

.bestabib__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.bestabib__navbar-sign .select,
.bestabib__navbar-sign .select:active {
  background: #ffffff;
  color: #344054;
}
.bestabib__navbar-sign .select::after {
  display: none;
}
.custom-select {
  width: 45px;
}

.bestabib__navbar-sign .options {
  box-sizing: border-box;
  right: 0px;
  bottom: -566px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
}

.bestabib__navbar-sign_options-account {
  position: absolute;
  inset: 0px auto auto 0px;
  transform: translate3d(1414px, 49.5px, 0px);
  background: white;
}

.bestabib__navbar-sign_options-account_profile,
.bestabib__navbar-sign_options-account_logout {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.bestabib__navbar-sign_options-account_logout {
  border-top: 1px solid #eaecf0;
}

.bestabib__navbar-sign_options-account_title {
  border-bottom: 1px solid #eaecf0;
}

.dropdown-item.active,
.dropdown-item:active {
  background: #ffffff;
  color: #344054;
}

.bestabib__navbar-sign_options-account
  .bestabib__navbar-sign_options-account_title-name {
  margin: 0;
}
.bestabib__navbar-sign_options-account_title p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 20px;
  color: #344054;
}
.bestabib__navbar-sign_options-account_title sub {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 20px;
  color: #475467;
}

.bestabib__navbar-sign_options-account a,
sub,
sup {
  font-size: 1.6rem;
  color: #1d1d1f;
}
sub,
sup {
  top: auto;
}

.bestabib__navbar-links_container p,
.bestabib__navbar-sign p,
.bestabib__navbar-menu_container p,
.bestabib__navbar-menu_container p a {
  color: #050505;

  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 25px;
  text-transform: capitalize;

  margin: 0 1rem;
  cursor: pointer;
}
.bestabib__navbar-menu_container p,
.bestabib__navbar-menu_container p a {
  color: #fff;
  text-decoration: none;
}

.bestabib__navbar-sign button,
.bestabib__navbar-menu_container button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: #ff4820;
  font-family: var(--font-family);
  font-weight: 500;
  /* font-size: 1.6em; */
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}

.bestabib__navbar-sign button a,
.bestabib__navbar-menu_container button a {
  color: #fff;
  text-decoration: none;
}
.bestabib__navbar-menu {
  margin-left: 1rem;
  z-index: 1;
  display: none;
  position: relative;
}

.bestabib__navbar-menu svg {
  cursor: pointer;
}

.bestabib__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  background: var(--color-toggleMenu);
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 220px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.bestabib__navbar-menu_container p {
  margin: 1rem 0;
}

.bestabib__navbar-menu_container-links-sign {
  display: none;
}

.bestabib__navbar-menu_container-links-sign .no-transform {
  text-transform: none;
}
@media screen and (max-width: 1050px) {
  .bestabib__navbar {
    margin: 0 20px 0 20px;
  }
  .bestabib__navbar-links_container {
    display: none;
  }

  .bestabib__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .bestabib__navbar {
    padding: 2rem 4rem;
  }
  .dropdown-item.active,
  .dropdown-item:active,
  .dropdown-item {
    color: #ffffff;
    background-color: unset;
  }
  .bestabib__navbar-menu_container-links-sign_body {
    margin-bottom: 19px;
  }
  .ltrMenu {
    align-items: start;
  }
}

@media screen and (max-width: 550px) {
  .bestabib__navbar {
    padding: 2rem;
  }

  .bestabib__navbar-sign {
    display: none;
  }

  .bestabib__navbar-menu_container {
    top: 20px;
  }

  .bestabib__navbar-menu_container-links-sign {
    display: block;
  }
}
