.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 100vh; /* Full viewport height */
  background-color: #f8f9fa; /* Light background color */
}

.custom-spinner {
  width: 80px;
  height: 80px;
  border-width: 6px; /* Thicker border for the spinner */
  margin-bottom: 20px; /* Space between spinner and text */
  color: #007bff; /* Primary color for the spinner */
}

.loader-text {
  font-size: 1.2rem;
  color: #6c757d; /* Secondary color for the text */
}
