.following-doctors {
  padding: 1em;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: #fafafa;
}

.following-doctors h2 {
  text-align: center;
  color: #333;
}

.following-doctors-list {
  list-style-type: none;
  padding: 0;
}

.doctor-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  margin-top: 1em;
  background-color: #fff;
}

.doctor-info span {
  margin-left: 1em;
  font-size: 0.9em;
  color: #777;
}

.unfollow-btn {
  padding: 10px 20px;
  border: 1px solid #ff6b6b; /* red border */
  border-radius: 25px; /* makes the button round */
  color: #ff6b6b; /* red text */
  background-color: #fff; /* white background */
  font-weight: bold; /* makes the text bold */
  cursor: pointer;
  outline: none; /* removes the default browser outline */
  transition: all 0.2s ease-in-out; /* smooth transition for hover effects */
}

.unfollow-btn:hover {
  background-color: #ffefef; /* slightly red on hover */
  box-shadow: 0px 4px 8px rgba(255, 107, 107, 0.2); /* subtle shadow on hover */
}

.unfollow-btn:active {
  transform: scale(0.95); /* slightly shrinks the button when pressed */
}
