.blog-section {
  /* max-width: 800px; */
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.blog-section h2 {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.blog-section button {
  background-color: var(--button-background-color);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1.6rem;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.blog-section button:hover {
  background-color: var(--button-hover-background-color);
}

.blog-section button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Form styles */
.blog-section form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.blog-section label {
  font-size: 1.6rem;
  color: #555;
}

.blog-section input[type='text'] {
  padding: 10px;
  font-size: 1.6rem;
  border: 1px solid #ccc;
  border-radius: 50px;
  outline: none;
  transition: border-color 0.3s;
  width: 100%;
}

.blog-section input[type='text']:focus {
  border-color: #2d0eaa;
}

.blog-section .ReactQuill {
  margin-top: 10px;
  border-radius: 5px;
}

/* Blog list styles */
.blog-list {
  margin-top: 40px;
}

.blog-item {
  background-color: #fafafa;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.blog-item:hover {
  background-color: #f1f1f1;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.blog-item h3 {
  font-size: 1.5rem;
  color: #333;
  margin: 0 0 10px;
}

.blog-item p {
  font-size: 1.6rem;
  color: #666;
  margin: 0 0 10px;
}

.blog-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.blog-actions button {
  background-color: #f44336;
  color: white;
  padding: 8px 12px;
  font-size: 1.6rem;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.blog-actions button:hover {
  background-color: #e53935;
}

/* Add/Edit Blog Form styles */
.blog-section form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 20px;
}

.blog-section form div {
  /* display: flex; */
  flex-direction: column;
  gap: 8px;
}

.blog-section label {
  font-size: 1.6rem;
  color: #333;
  font-weight: bold;
}

.blog-section input[type='text'] {
  padding: 12px;
  font-size: 1.6rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
}

.blog-section input[type='text']:focus {
  border-color: #234dae;
}

.blog-section .ReactQuill {
  margin-top: 10px;
  border-radius: 5px;
  height: 200px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
}

/* Submit Button */
.blog-section button[type='submit'] {
  background-color: var(--button-background-color);
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 1.6rem;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.blog-section button[type='submit']:hover {
  background-color: var(--button-hover-background-color);
}

.blog-section button[type='submit']:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .blog-section {
    padding: 15px;
    margin: 10px;
  }

  .blog-section h2 {
    font-size: 1.5rem;
  }

  .blog-section button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .blog-item {
    padding: 10px;
  }

  .blog-item h3 {
    font-size: 1.2rem;
  }

  .blog-item p {
    font-size: 0.9rem;
  }
}
