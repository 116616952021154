.bestabib__brand {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.bestabib__brand div {
  flex: 1;
  max-width: 150px;
  min-width: 120px;
  margin: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.bestabib__brand div {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 3rem;
  line-height: 75px;
  letter-spacing: -0.04em;
  text-align: left;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .bestabib__brand div {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 3rem;
    line-height: 75px;
    letter-spacing: -0.04em;
    text-align: left;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
}

@media (max-width: 480px) {
  .bestabib__brand div {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 3rem;
    line-height: 75px;
    letter-spacing: -0.04em;
    text-align: left;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
}
