/* SearchBox.css */
.searchBox-heading h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 3rem;
  line-height: 75px;
  letter-spacing: -0.04em;
  text-align: left;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.searchBox-Container {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 50px;
  padding: 10px 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  flex-wrap: wrap; /* Allows wrapping on small screens */
}

.inputs-container {
  display: flex;
  flex: 1;
  gap: 15px;
  flex-wrap: wrap; /* Makes inputs wrap on smaller screens */
}

.input-icon-container {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 150px; /* Minimum width for smaller screens */
}

.searchInput {
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 10px 10px 10px 35px; /* Extra padding to make space for the icon */
  border-radius: 50px;
  background-color: #f5f5f5;
  color: #333;
}

.searchInput::placeholder {
  color: #aaa;
  font-weight: 300;
}

.icon {
  position: absolute;
  left: 10px;
  color: #aaa;
  font-size: 18px;
}

.gender-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center; /* Center aligns on small screens */
  margin: 10px 0;
}

.gender-container label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}

.gender-container input[type='radio'] {
  margin-right: 5px;
  accent-color: #007bff; /* Adjust accent color to match theme */
}

.primary-cta {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff; /* Blue color for the button */
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 10px;
  align-self: center;
}

.primary-cta:hover {
  background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .inputs-container {
    flex-direction: column;
    gap: 10px;
  }

  .input-icon-container {
    margin-right: 0;
  }

  .primary-cta {
    width: 100%; /* Button spans full width on smaller screens */
    margin-top: 1rem;
  }
  .searchBox-heading h1 {
    font-size: 48px;
    text-align: center;
    line-height: 60px;
  }
}

@media (max-width: 480px) {
  .searchBox-Container {
    padding: 10px;
  }

  .searchBox-heading h1 {
    font-size: 36px;
    text-align: center;
    line-height: 48px;
  }

  .searchInput {
    font-size: 14px; /* Adjust font size for smaller screens */
    padding: 8px 8px 8px 30px;
  }

  .icon {
    font-size: 16px;
  }

  .gender-container label {
    font-size: 12px; /* Smaller font for labels on small screens */
  }

  .primary-cta {
    font-size: 14px; /* Smaller button font for small screens */
    margin-top: 1rem;
  }
}
